<template>
  <div id="app">
    <div class="page-bg page"></div>
    <div class="page1 page ">
      <img src="~/img/logo.png" alt="" class="logo" />
      <img src="~/img/logo2.png" alt="" class="logo2" />
      
      <div class="center">
        <div class="p1-box">
          <div class="ab wh100 bg_ball">
            <img src="~/img/p1-4.png" alt="" class="p1-2" />
            <img src="~/img/p1-1.png" alt="" class="p1-3" />

            <img src="~/img/p1-10.png" alt="" class="p1-4" />
            <img src="~/img/p1-12.png" alt="" class="p1-5" />
            <img src="~/img/p1-11.png" alt="" class="p1-6" />

            <img src="~/img/p1-2.png" alt="" class="p1-7" />
            <img src="~/img/p1-3.png" alt="" class="p1-8" />

            <img src="~/img/p1-7.png" alt="" class="p1-9" />

            <div class="p1-10">
              <img src="~/img/p1-8.png" alt="" class="w100" />
            </div>
            <div class="p1-11">
              <img src="~/img/p1-9.png" alt="" class="w100" />
            </div>
          </div>
        </div>

        <img src="~/img/p1-title.png" alt="" class="p1-1" />
      </div>
    </div>

    <div class="next page">
        <div class="b-ani">
        <img src="~/img/page-b-img1.png" alt="" class="img1" />
        <img src="~/img/page-b-img2.png" alt="" class="img2" />
      </div>
        <div class="center page2 dn">
          <!-- <div class="p2-title">
            <img src="~/img/p2-1.png" alt="" class="p2-1" />
            <img src="~/img/p2-2.png" alt="" class="p2-2" />
            <img src="~/img/p2-3.png" alt="" class="p2-3" />
          </div> -->

          <div class="p2-box">
            <img src="~/img/p2-5.png" alt="" class="p2-4" />
            <img src="~/img/p2-6.png" alt="" class="p2-5" />
            <img src="~/img/p2-7.png" alt="" class="p2-6" />
            <img src="~/img/p2-8.png" alt="" class="p2-7" />
            <img src="~/img/p2-9.png" alt="" class="p2-8" />
          </div>
        </div>

     <div class="center page3 dn">
        <div class="p3-box">
            <img src="~/img/p3-2.png" alt="" class="p3-1">
            <!-- <img src="~/img/p3-3.png" alt="" class="p3-2"> -->
            <img src="~/img/p3-4.png" alt="" class="p3-2">
            <img src="~/img/p3-5.png" alt="" class="p3-3">
            <img src="~/img/p3-6.png" alt="" class="p3-4">
            <!-- <img src="~/img/p3-txt.png" alt="" class="p3-5"> -->
            
        </div>
      </div>
    </div>

    <div class="center page4 dn">
       <div class="p4-box">
            <img src="~/img/p4-6.png" alt="" class="p4-1">

            <img src="~/img/p4-2.png" alt="" class="p4-2">
            <img src="~/img/p4-3.png" alt="" class="p4-3">
            <img src="~/img/p4-4.png" alt="" class="p4-4">
            <img src="~/img/p4-5.png" alt="" class="p4-5">
       </div>
    </div>
      <div class="center page5 dn">
       <div class="p5-box">
            <img src="~/img/p5-1.png" alt="" class="p5-1">
            <img src="~/img/p5-2.png" alt="" class="p5-2">
            <img src="~/img/p5-3.png" alt="" class="p5-3">
            <img src="~/img/p5-4.png" alt="" class="p5-4">
            <img src="~/img/p5-5.png" alt="" class="p5-5">
            <img src="~/img/p5-6.png" alt="" class="p5-6">
            <img src="~/img/p5-7.png" alt="" class="p5-7">
       </div>
       
    </div>
    

       <div class="center page6 dn">
            <img src="~/img/p1-title.png" alt="" class="p6-1">
            <img src="~/img/p6-1.png" alt="" class="p6-2">
            <img src="~/img/p6-2.png" alt="" class="p6-3">
       </div>

    <img src="~/img/gg.png" alt="" class="gg" />

    <div :class="['biaoqian', isplay ? '' : 'cur',index>1 ?'sy':'']" @click="playAudio"></div>
  </div>
</template>

<script>
import * as gsap from "gsap";

export default {
  name: "App",
  data() {
    return {
      imgArr: [],
      r: window.innerWidth / 750,
      state: 0,
      isplay: true,
      index:1
    };
  },

  mounted() {
    this.util.init();

    let p1 = new gsap.TimelineLite();
    p1.add( gsap.TweenMax.fromTo( ".p1-1", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ) );
    p1.add( gsap.TweenMax.fromTo( ".p1-box", 2, { y: 1000, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.7" );

    p1.add( gsap.TweenMax.fromTo( ".p1-4", 1.5, { scaleY: 0 }, { scale: 1, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.7" );
    p1.add( gsap.TweenMax.fromTo( ".p1-6", 1.5, { scaleY: 0 }, { scale: 1, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.7" );

    p1.add( gsap.TweenMax.fromTo( ".p1-10", 1.5, { scale: 0.8, opacity: 1, x: -100 }, { scale: 1, opacity: 1, x: 0, ease: gsap.Expo.easeOut } ), "-=1.7" );
    p1.add( gsap.TweenMax.fromTo( ".p1-9", 1.5, { scale: 0.8, opacity: 1, y: 100 }, { scale: 1, opacity: 1, y: 0, ease: gsap.Expo.easeOut } ), "-=1.7" );
    p1.add( gsap.TweenMax.fromTo( ".p1-11", 1.5, { scale: 0.8, opacity: 1, x: 100 }, { scale: 1, opacity: 1, x: 0, ease: gsap.Expo.easeOut } ), "-=1.7" );


    let p2 = new gsap.TimelineLite();
    // p2.add( gsap.TweenMax.fromTo( ".p2-title", 2, { y: 1000, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ) );
    // p2.add( gsap.TweenMax.fromTo( ".p2-1", 1, { x: -1000, opacity: 0 }, { x: 0, opacity: 1, ease: gsap.Expo.easeOut } ));
    // p2.add( gsap.TweenMax.fromTo( ".p2-2", 1, { x: 1000, opacity: 0 }, { x: 0, opacity: 1, ease: gsap.Expo.easeOut } ),'-=1.5');
    // p2.add( gsap.TweenMax.fromTo( ".p2-3", 3, { scale: 0.9 }, { scale: 1, ease: gsap.Expo.easeOut } ),'-=2');
    p2.add( gsap.TweenMax.fromTo( ".p2-box", 2, { scale: 0}, { scale: 1, ease: gsap.Expo.easeOut } ));

    p2.add( gsap.TweenMax.fromTo( ".p2-4", 2, { scale: 0}, { scale: 1, ease: gsap.Expo.easeOut } ),'-=1');
    p2.add( gsap.TweenMax.fromTo( ".p2-5", 2, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ),'-=1');
    p2.add( gsap.TweenMax.fromTo( ".p2-6", 2, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ),'-=1');

    p2.add( gsap.TweenMax.fromTo( ".p2-7", 2, { scale: 0}, { scale: 1, ease: gsap.Expo.easeOut } ),'-=1');
    p2.add( gsap.TweenMax.fromTo( ".p2-8", 2, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ),'-=1');
    p2.pause();

    let p3 = new gsap.TimelineLite();
    p3.add( gsap.TweenMax.fromTo( ".p3-box", 2, { scale: 0}, { scale: 1, ease: gsap.Expo.easeOut } ));
    p3.add( gsap.TweenMax.fromTo( ".p3-1", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut }),'-=1');
    p3.add( gsap.TweenMax.fromTo( ".p3-2", 2, { y: 100,opacity: 0}, { y: 1,opacity:1, ease: gsap.Expo.easeOut }),'-=0.7');
    p3.add( gsap.TweenMax.fromTo( ".p3-3", 2, { y: 100,opacity: 0}, { y: 1,opacity:1, ease: gsap.Expo.easeOut }),'-=0.7');
    p3.add( gsap.TweenMax.fromTo( ".p3-4", 2, { y: 100,opacity: 0}, { y: 1,opacity:1, ease: gsap.Expo.easeOut }),'-=0.7');
    p3.add( gsap.TweenMax.fromTo( ".p3-5", 2, { y: 100,opacity: 0}, { y: 1,opacity:1, ease: gsap.Expo.easeOut }),'-=0.7');
    p3.pause();

     let p4 = new gsap.TimelineLite();
    p4.add( gsap.TweenMax.fromTo( ".p4-box", 2, { scale: 0}, { scale: 1, ease: gsap.Expo.easeOut } ));
    p4.add( gsap.TweenMax.fromTo( ".p4-1", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut }),'-=1');
     p4.add( gsap.TweenMax.fromTo( ".p4-2", 2, { y: 100,opacity: 0}, { y: 1,opacity:1, ease: gsap.Expo.easeOut }),'-=0.7');
    p4.add( gsap.TweenMax.fromTo( ".p4-3", 2, { y: 100,opacity: 0}, { y: 1,opacity:1, ease: gsap.Expo.easeOut }),'-=0.7');
    p4.add( gsap.TweenMax.fromTo( ".p4-4", 2, { y: 100,opacity: 0}, { y: 1,opacity:1, ease: gsap.Expo.easeOut }),'-=0.7');
    p4.add( gsap.TweenMax.fromTo( ".p4-5", 2, { y: 100,opacity: 0}, { y: 1,opacity:1, ease: gsap.Expo.easeOut }),'-=0.7');
    p4.pause();

      let p5 = new gsap.TimelineLite();
    p5.add( gsap.TweenMax.fromTo( ".p5-box", 2, { scale: 0}, { scale: 1, ease: gsap.Expo.easeOut } ));
    p5.add( gsap.TweenMax.fromTo( ".p5-1", 1.5, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut }),'-=1');
    p5.add( gsap.TweenMax.fromTo( ".p5-2", 1.5, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut }),'-=0.3');
    p5.add( gsap.TweenMax.fromTo( ".p5-3", 1.5, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut }),'-=.8');
    p5.add( gsap.TweenMax.fromTo( ".p5-4", 1.5, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut }),'-=.8');
    p5.add( gsap.TweenMax.fromTo( ".p5-5", 1.5, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut }),'-=.8');
    p5.add( gsap.TweenMax.fromTo( ".p5-6", 1.5, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut }),'-=.8');
    p5.add( gsap.TweenMax.fromTo( ".p5-7", 1.5, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut }),'-=.8');
    // p5.pause();
 

   let p6 = new gsap.TimelineLite();
    p6.add( gsap.TweenMax.fromTo( ".p6-1", 2, { scale: 0}, { scale: 1, ease: gsap.Expo.easeOut } ));
    p6.add( gsap.TweenMax.fromTo( ".p6-2", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut }),'-=1');
    p6.add( gsap.TweenMax.fromTo( ".p6-3", 2, { y: 100,opacity: 0}, { y: 1,opacity:1, ease: gsap.Expo.easeOut }),'-=1');

    let animateList = [p1, p2, p3, p4, p5,p6];

    const next = () => {
      if(!animateList[this.index]) return
      $(".page" + this.index).fadeOut(500);
      this.index++;
      $(".page" + this.index).fadeIn(500);

      if(this.index == 2){
        $('.b-ani').fadeIn(500);
      }

      animateList[this.index - 1].restart();
    };
    const prve = () => {
      if(this.index == 1)return;
 
      $(".page" + this.index).fadeOut(500);
      this.index--;

        $(".page" + this.index).fadeIn(500);
    if(this.index == 1){
        $('.b-ani').fadeOut(500);
      }

      animateList[this.index - 1].restart();
    };
    $("#app")
      .on("swipeUp", () => {
        next();
      })
      .on("swipeDown", () => {
        prve();
      });
  },
  methods: {
    playAudio() {
      if (this.isplay) {
        this.audio.musicPlay(0);
      } else {
        this.audio.musicPlay(1);
      }
      this.isplay = !this.isplay;
    },
  },
};
</script>


