import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false

import 'css/animate.min.css';
import 'css/customAnimate.css';

import 'css/public.css';
import 'css/index.less';

import * as util from 'js/lib/util'
Vue.prototype.util = util;

import touch from "js/lib/touch.js"
touch($);


import 'vant/lib/index.css';


import { bgAudioFix } from "js/lib/audio.js"

let $audio = new bgAudioFix({
  src: 'https://spdb-yt.h5.yscase.com/music.mp3',
  autoPlay:true
});

Vue.prototype.audio = $audio;

// import Vconsole from 'vconsole'
//  const vConsole = new Vconsole()


// import WechatJSSDK from '@yushuo/wechat-jssdk'


new Vue({
  render: h => h(App),
}).$mount('#app')
